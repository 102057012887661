import { TipoTramiteModel } from "src/app/models/tipoTramite.model";
import { ClasificacionTramiteModel } from "./dtos/clasificacion.tramite.model";
import { DocumentoArchivoModel } from "./dtos/documento.archivo.model";
import { FaseModel, TramiteFase } from "./dtos/fase.model";
import { ResolucionModel } from "./dtos/resolucion.model";
import { TipoRecursoModel } from "./dtos/tipo.recurso.model";
import { FormaPeticionModel } from "./forma.peticion";
import { InteresadoModel } from "./interesado";
import { PredioModel } from "./predio.model";
import { SolicitanteModel } from "./solicitante.model";
import { SolicitudModel } from "./solicitud";
import { TramiteObservacionModel } from "./tramite.observacion.model";

export class TramiteModel {
  id: number;
  tramiteOrigenId: number;
  origen: TramiteModel;
  clasificacionTramiteId: number;
  clasificacion: ClasificacionTramiteModel;
  numRadicado: string;
  numSolicitud: string;
  noResolucion: string;
  solicitudId: number;
  solicitud: SolicitudModel;
  radicadorId: number;
  radicador: string;
  tramiteEstadoId: number;
  tramiteTipoId: number;
  tramiteTipo: TipoTramiteModel;
  // tipoTramite: TipoTramiteModel;
  fechaCreacion: Date;
  fechaRadicacion: Date;
  fechaEsperaFirma: Date;
  fechaEsperaRecurso: Date;
  fechaFin: Date;
  fase: FaseModel;
  predios: string[];
  prediosModel: PredioModel[];
  interesados: string[];
  interesadosModel: InteresadoModel[];
  documentos: number;
  resolucion: ResolucionModel;
  responsable: string;
  tipoRecursoId: number;
  tipoRecurso: TipoRecursoModel;
  geo: boolean;
  observaciones: string;
  solicitante: SolicitanteModel;
  solicitantes: string[];
  inicial: TramiteModel;
  recurso: boolean;
  recursoProspera: boolean;
  recursosProceden: string;
  recursosProcedenDesc: string;
  dummyTramitePredioId: number;
  mismosPropietariosDestino: boolean;
  informeTecnico: DocumentoArchivoModel;
  tramiteTipoInicial: TipoTramiteModel;
  campo: boolean;
  formaPeticion: FormaPeticionModel;
  formaPeticionId: number;
  rectificacionAreaTotalTerreno: boolean;
  urlExpediente: string;
  codigoMunicipio: string;
  jobId: string;
  oficinaId: number;
  totalTramitesEspera: number;
  tramiteFase: TramiteFase;
  tramiteObservacion: TramiteObservacionModel;
  resolucionAnaloga: DocumentoArchivoModel;
  desicion: string;
  etapaProbatoria: boolean;
  hint: string;
  permiteFlujoRapido: boolean;
  flujoRapido: boolean;
  ejecutorId: number;
  finesRegistrales: boolean;
  rectificacionAreaTerreno: boolean;
  faseId: number;
  plantillaAnterior: boolean;
  owner: string;
  tieneVersion: boolean;
  rechazado: boolean;
}
